<template>
  <div class="mt-10">
    <h1 class="section__headline">Edición del producto</h1>
    <v-breadcrumbs :items="breadcrumb" divider="->" />

    <v-container>
      <v-row class="justify-content-center">
        <div class="col-xxl-8 order-2 order-xxl-1">
          <v-card v-if="isFetching" flat min-height="400px" class="text-center">
            <h1 class="mt-4">Cargando producto ...</h1>
            <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin: 0 auto" />
          </v-card>

          <v-card v-if="!isFetching" flat class="card card-custom card-stretch gutter-b">
            <div class="card-body pt-5 pb-3">
              <div>
                <div class="card__title">Nombre</div>

                <v-text-field dense v-model="newProduct.name" outlined label="Nombre" style="min-height: 25px" rows="4"
                  row-height="30" class="mt-4" :error-messages="newProductNameErrors"
                  @blur="$v.newProduct.name.$touch()" @input="$v.newProduct.name.$touch()"></v-text-field>
              </div>

              <div>
                <div class="card__title">Descripción</div>

                <v-text-field dense v-model="newProduct.description" outlined label="Descripción"
                  style="min-height: 25px" rows="4" row-height="30" class="mt-4"
                  :error-messages="newProductDescriptionErrors" @blur="$v.newProduct.description.$touch()"
                  @input="$v.newProduct.description.$touch()"></v-text-field>
              </div>

              <div>
                <div class="card__title">Imagen</div>

                <v-text-field dense v-model="newProduct.pic" outlined label="Imagen" style="min-height: 25px" rows="4"
                  row-height="30" class="mt-4" :error-messages="newProductPicErrors" @blur="$v.newProduct.pic.$touch()"
                  @input="$v.newProduct.pic.$touch()"></v-text-field>
              </div>
              <div v-if="newProduct?.pic?.length > 8">
                <v-img :lazy-src="newProduct?.pic" max-height="150" max-width="250" :src="newProduct?.pic"></v-img>
              </div>

              <div>
                <div class="card__title">Ingredientes</div>

                <v-text-field dense v-model="newProduct.ingredients" outlined label="Ingredientes"
                  style="min-height: 25px" rows="4" row-height="30" class="mt-4"
                  :error-messages="newProductIngredientsErrors" @blur="$v.newProduct.ingredients.$touch()"
                  @input="$v.newProduct.ingredients.$touch()"></v-text-field>
              </div>
              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">Precio</div>

                    <v-text-field dense v-model="newProduct.price" outlined label="Precio" style="min-height: 25px"
                      rows="4" row-height="30" class="mt-4" :error-messages="newProductPriceErrors"
                      @blur="$v.newProduct.price.$touch()" @input="$v.newProduct.price.$touch()"></v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">Precio para oferta local</div>

                    <v-text-field dense v-model="newProduct.localPrice" outlined label="Precio para oferta local"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4"
                      :error-messages="newProductLocalPriceErrors" @blur="$v.newProduct.localPrice.$touch()"
                      @input="$v.newProduct.localPrice.$touch()"></v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">Opción sin gluten</div>

                    <v-switch :input-value="newProduct.glutenFree" v-model="newProduct.glutenFree" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">Variantes</div>

                    <v-select dense v-model="newProduct.variants" small-chips outlined multiple label="Variantes"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4" :items="variantsFormatted"
                      item-value="value" item-text="name" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">Extras</div>

                    <v-select dense small-chips v-model="newProduct.extras" outlined multiple label="Extras"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4" :items="extrasFormatted"
                      item-value="value" item-text="name" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">Sizes</div>

                    <v-select dense small-chips v-model="newProduct.sizes" outlined multiple label="Sizes"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4" :items="sizesFormatted"
                      item-value="value" item-text="name" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">Categorías</div>

                    <v-select dense small-chips v-model="newProduct.category" outlined label="Categorías"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4" :items="categoriesFormatted"
                      item-text="name" item-value="value" :error-messages="newProductCategoryErrors"
                      @blur="$v.newProduct.category.$touch()" @input="$v.newProduct.category.$touch()" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">Alérgenos</div>

                    <v-select dense small-chips multiple v-model="newProduct.allergens" outlined label="Alergenos"
                      style="min-height: 25px" rows="4" row-height="30" class="mt-4" :items="allergensFormatted"
                      item-text="name" item-value="value" />
                  </div>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn class="boton font-14 px-9 py-4 my-3" @click="saveProduct">
                  <v-icon class="mr-2">
                    {{ id ? 'mdi-pencil' : 'mdi-plus' }}
                  </v-icon>
                  {{ id ? 'ACTUALIZAR PRODUCTO' : 'CREAR PRODUCTO' }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
} from '@/core/services/store/products.module';
import { GET_VARIANTS } from '@/core/services/store/variants.module';
import { GET_EXTRAS } from '@/core/services/store/extras.module';
import { GET_CATEGORIES } from '@/core/services/store/categories.module';
import { GET_ALLERGENS } from '@/core/services/store/allergens.module';
import { GET_SIZES } from '@/core/services/store/sizes.module';
import {
  required,
  decimal,
  maxLength,
  minLength,
  url,
} from 'vuelidate/lib/validators';

export default {
  name: 'ProductPage',
  components: {},
  data() {
    return {
      breadcrumb: [
        {
          text: 'Carta',
          disabled: false,
          href: '/products',
        },
        {
          text: 'Edición del producto',
          disabled: true,
        },
      ],
      id: null,
      isFetching: false,
      newProduct: {
        name: '',
        description: '',
        pic: '',
        ingredients: '',
        price: 5,
        localPrice: null,
        sizes: [],
        variants: [],
        extras: [],
        category: null,
        glutenFree: false,
        allergens: [],
      },
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  validations: {
    newProduct: {
      name: { required, minLength: minLength(5), maxLength: maxLength(75) },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(175),
      },
      pic: { url },
      ingredients: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(175),
      },
      price: {
        required,
        decimal,
      },
      localPrice: {
        decimal,
      },
      category: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      product: (state) => state.products.item,
      extras: (state) => state.extras.items,
      variants: (state) => state.variants.items,
      categories: (state) => state.categories.items,
      allergens: (state) => state.allergens.items,
      sizes: (state) => state.sizes.items,
      isFetching: (state) => state.products.isFetching,
    }),
    newProductNameErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.name.$dirty) return errors;
      !this.$v.newProduct.name.required &&
        errors.push('Es necesario introducir un nombre.');
      !this.$v.newProduct.name.minLength &&
        errors.push('El nombre debe tener un mínimo de 5 posiciones.');
      !this.$v.newProduct.name.maxLength &&
        errors.push('El nombre debe tener un máximo de 75 posiciones.');
      return errors;
    },
    newProductDescriptionErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.description.$dirty) return errors;
      !this.$v.newProduct.description.required &&
        errors.push('Es necesario introducir una descripción.');
      !this.$v.newProduct.description.minLength &&
        errors.push('La descripción debe tener un mínimo de 5 posiciones.');
      !this.$v.newProduct.description.maxLength &&
        errors.push('La descripción debe tener un máximo de 75 posiciones.');
      return errors;
    },
    newProductPicErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.pic.$dirty) return errors;
      !this.$v.newProduct.pic.url &&
        errors.push(
          'Es necesario introducir una dirección correcta para la imagen.'
        );
      return errors;
    },
    newProductIngredientsErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.ingredients.$dirty) return errors;
      !this.$v.newProduct.ingredients.required &&
        errors.push('Es necesario introducir los ingredientes del producto.');
      !this.$v.newProduct.ingredients.minLength &&
        errors.push('Los ingredientes deben tener un mínimo de 5 posiciones.');
      !this.$v.newProduct.ingredients.maxLength &&
        errors.push('Los ingredientes deben tener un máximo de 75 posiciones.');
      return errors;
    },
    newProductPriceErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.price.$dirty) return errors;
      !this.$v.newProduct.price.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newProduct.price.decimal &&
        errors.push('El precio debe ser un valor numérico.');
      return errors;
    },
    newProductLocalPriceErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.localPrice.$dirty) return errors;
      !this.$v.newProduct.localPrice.decimal &&
        errors.push('El precio debe ser un valor numérico.');
      return errors;
    },
    newProductCategoryErrors() {
      const errors = [];
      // debugger;
      if (!this.$v.newProduct.category.$dirty) return errors;
      !this.$v.newProduct.category.required &&
        errors.push('Es necesario especificar una catergoría.');
      return errors;
    },

    variantsFormatted() {
      return this.variants.map((variant) => {
        return { name: variant.name, value: variant._id };
      });
    },
    categoriesFormatted() {
      return this.categories.map((category) => {
        return { name: category.name, value: category._id };
      });
    },
    extrasFormatted() {
      return this.extras.map((extra) => {
        return { name: extra.name, value: extra._id };
      });
    },
    allergensFormatted() {
      return this.allergens.map((allergen) => {
        return { name: allergen.name, value: allergen._id };
      });
    },
    sizesFormatted() {
      return this.sizes.map((size) => {
        return { name: size.name, value: size._id };
      });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    // debugger;
    if (this.id) {
      this.$store.dispatch(GET_PRODUCT, this.$route.params.id).then(() => {
        this.newProduct = this.product;
        this.newProduct.allergens = this.product.allergens.map(
          (allergen) => allergen._id
        );
        this.newProduct.sizes = this.product.sizes.map((size) => size._id);
        this.newProduct.variants = this.product.variants.map(
          (variant) => variant._id
        );
      });
    }
    console.log(this.product);
    console.log(this.newProduct);
    this.$store.dispatch(GET_VARIANTS);
    this.$store.dispatch(GET_EXTRAS);
    this.$store.dispatch(GET_ALLERGENS);
    this.$store.dispatch(GET_SIZES);
    this.$store.dispatch(
      GET_CATEGORIES,
      this.$store.state.auth.user.seller._id
    );
    console.log(this.product);
  },
  methods: {
    saveProduct() {
      debugger;
      this.$v.$reset();
      console.log(this.newProduct);
      this.$v.$touch();
      console.log(this.$v.$each);
      // debugger;
      if (this.$v.$invalid) {
      } else {
        // let newCp = {
        //   code: this.cp.code,
        //   price: Number(this.cp.price),
        //   minPrice: Number(this.cp.minPrice),
        //   localPrice: Number(this.cp.localPrice),
        //   local: this.cp.local ? true : false,
        // };
        if (this.$route.params.id) {
          // if (!this.new.newProduct.sizes) this.newProduct.sizes = null;
          // if (!this.newProduct.variants) this.newProduct.variants = null;
          // if (!this.newProduct.extras) this.newProduct.extras = null;
          this.$store.dispatch(UPDATE_PRODUCT, this.newProduct);
        } else {
          this.newProduct.owner = this.$store.state.auth.user.seller._id;
          this.$store.dispatch(CREATE_PRODUCT, this.newProduct);
        }
      }
    },
  },
};
</script>
<style>
.card__title {
  display: block;
  padding-top: 4px;
  font-size: 16px !important;
  font-weight: 700;
  color: #333333;
}
</style>
